import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { Router, withRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import initI18n from './services/i18n';
import { history } from './services/routing';
import { store } from './services/redux';

import { BRAND_NAME } from './config';

const ConnectedApp = React.lazy(() => {
  return import('./App');
});

const PositionRestore = withRouter(props => {
  const [location, setLocation] = useState(props.location.pathname);
  if (location !== props.location.pathname) {
    setLocation(props.location.pathname);
    if (props.location.state && props.location.state.sectionId) {
      setTimeout(() => {
        const element = document.getElementById(props.location.state.sectionId);
        if (element) element.scrollIntoView({ behavior: 'smooth' });
      }, 0);
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  return null;
});

function Init() {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    Promise.all([initI18n(BRAND_NAME)]).then(() => {
      setLoaded(true);
    });
  }, []);
  return loaded ? (
    <React.Suspense fallback={null}>
      <Provider store={store}>
        <Router history={history}>
          <PositionRestore />
          <React.Fragment>
            <ConnectedApp />
          </React.Fragment>
        </Router>
      </Provider>
    </React.Suspense>
  ) : null;
}

if (module.hot) {
  module.hot.accept(render);
}

function render() {
  const rootElement = document.getElementById('root');
  ReactDOM.render(<Init />, rootElement);
}

render();
