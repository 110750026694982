/* eslint-disable global-require */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {},
    lng: 'de',
    keySeparator: '.', // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default brandName => {
  return new Promise(res => {
    const namespaces = [
      'config',
      'form',
      'data',
      'contact',
      'confirmation',
      'rejection',
      'validation',
      'footer',
      'header',
      'error',
      'calculation',
      'cookie',
      'contactModal',
    ];
    Promise.all(
      namespaces.map(item => {
        return import(`../../resources/${brandName}/content/${item}.json`).then(
          module => {
            i18n.addResourceBundle('de', item, module.default, true, true);
          }
        );
      })
    ).then(res);
  });
};
