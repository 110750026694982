export const BRAND_NAME = process.env.REACT_APP_WEBSITE_BRAND;

export const CONNECTION_TIMEOUT = 30000;
export const NUMBER_FORMAT = 'en-AU';
export const CURRENCY = 'AUD';
export const CURRENCY_SYMBOL = '$';

export const brandName = (() => {
  switch (BRAND_NAME) {
    case 'vwfs':
    case 'vwcv':
    case 'vw5':
    case 'vw6':
      return 'vw';
    default:
      return BRAND_NAME;
  }
})();

/**
 * Brand name as shown in the UI.
 */
export const brandNameForDisplay = (() => {
  switch (BRAND_NAME) {
    case 'vwfs':
      return 'VWFS';
    case 'vwcv':
    case 'vw5':
    case 'vw6':
      return 'VOLKSWAGEN';
    case 'skoda':
      return 'ŠKODA';
    case 'audi':
      return 'AUDI';
    default:
      console.error('Brand lacks definition of display format.');
      return BRAND_NAME;
  }
})();

export const ANALYTICS_DEFAULTS = {
  viewChange: '',
  pagename: '',
  platform: 'autocredit',
  country: 'AU',
  language: 'en',
  brand: brandName,
};
