export const FETCH_STOREFRONT_PENDING = 'storefront//FETCH_STOREFRONT_PENDING';
export const FETCH_STOREFRONT_DONE = 'storefront//FETCH_STOREFRONT_DONE';
export const FETCH_STOREFRONT_ERROR = 'storefront//FETCH_STOREFRONT_ERROR';

export function pending() {
  return {
    type: FETCH_STOREFRONT_PENDING,
    payload: {},
  };
}

export function done(data) {
  return {
    type: FETCH_STOREFRONT_DONE,
    payload: {
      data,
    },
  };
}

export function error(err) {
  return {
    type: FETCH_STOREFRONT_ERROR,
    payload: {
      err,
    },
  };
}

export function getStorefrontData(store) {
  return store.storefront.data;
}

const initialState = {
  pending: false,
  data: {},
  error: null,
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_STOREFRONT_PENDING:
      return {
        ...state,
        data: {},
        pending: true,
        error: null,
      };

    case FETCH_STOREFRONT_DONE:
      return {
        ...state,
        data: payload.data,
        pending: false,
        error: null,
      };
    case FETCH_STOREFRONT_ERROR:
      return {
        ...state,
        data: {},
        pending: false,
        error: true,
      };

    default:
      return state;
  }
}
