export const SET_CURRENT_PAGE = 'common//SET_CURRENT_PAGE';
export const SET_ERROR = 'common//SET_ERROR';

export const setCurrentPage = data => {
  return {
    type: SET_CURRENT_PAGE,
    payload: {
      data,
    },
  };
};

export const getCurrentPage = store => {
  return store.common.currentPage;
};

export const setError = data => {
  return {
    type: SET_ERROR,
    payload: {
      data,
    },
  };
};

export const getError = store => {
  return store.common.error;
};

const initialState = {
  currentPage: '',
  error: 'API error',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload.data,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload.data,
      };

    default:
      return state;
  }
}
